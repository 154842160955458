import { createStore, applyMiddleware } from 'redux'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createBrowserHistory } from 'history'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore' // make sure you add this for firestore
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'Sagas'
import createRootReducer from './index'

const nextRootReducer = require('.').default

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})

// Don't remove the commented history variable below. It is used for dell pre-packaging.
// export const history = createHashHistory();

export default () => {
  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware, createRouterMiddleware(history)]

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware))

  // Do we have preloaded state available? Great, save it.
  const initialState = { firebase: { authError: null } }

  const store = createStore(createRootReducer(history), initialState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('./index', () => {
        store.replaceReducer(nextRootReducer)
      })
    }
  }

  // kick off root saga
  sagaMiddleware.run(rootSaga)

  return {
    store,
    history,
  }
}
