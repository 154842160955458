import { call, put, select } from 'redux-saga/effects'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { pathOr } from 'ramda'
import JSZip from 'jszip'
import { cloneDeep, findIndex, clone } from 'lodash'
import AssembleActions from 'Redux/AssembleRedux'
import ASSEMBLE_API from 'Services/assembleApi'
import { WEBSITE_CONFIG_ID, WEBSITE_CONFIG_COLLECTION } from 'Config'

export const assembleApi = ASSEMBLE_API.create()

const DISPLAYS = ['leftDisplay', 'centerDisplay', 'rightDisplay']
const getIdToken = () => firebase.auth().currentUser.getIdToken()
const getConfig = state => state.firestore.data[WEBSITE_CONFIG_COLLECTION][WEBSITE_CONFIG_ID]
const getState = state => state.assemble

function* boiler(endpoint, ...params) {
  const config = yield select(getConfig)
  if (config.sso) {
    try {
      const idToken = yield call(getIdToken)
      const results = yield call(assembleApi.callEndpoint, endpoint, idToken, config.id, ...params)
      return results.status === 200 && results.data
        ? { status: 'Success', data: results.data, message: 'Success' }
        : { status: 'Failure', message: pathOr('Unknown Error', ['data', 'detail'], results) }
    } catch (error) {
      return { status: 'Failure', message: error }
    }
  }
}

export function* initiateRepo() {
  const results = yield call(boiler, 'initiateRepo')
  const res = { ...results.data, message: `Initiate Repo: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* commitRepo({ repo }) {
  const results = yield call(boiler, 'commitRepo', repo)
  const res = { ...results.data, message: `Commit Repo: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* refreshRepo({ repo }) {
  const results = yield call(boiler, 'refreshRepo', repo)
  const res = { ...results.data, message: `Refresh Repo: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* resetRepo({ repo }) {
  const results = yield call(boiler, 'resetRepo', repo)
  const res = { ...results.data, message: `Reset Repo: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* createSimulation({ repo, newSimName, marketingExp }) {
  const results = yield call(boiler, 'createSimulation', repo, newSimName, marketingExp)
  const res = { message: `Create Simulation: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    res.simulations = [...assemble.simulations, results.data]
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* updateSimulation({ repo, simId, data }) {
  const results = yield call(boiler, 'updateSimulation', repo, simId, data)
  const res = { ...results.data, message: `Update Simulation: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* createSimulationComment({ repo, simId, comment }) {
  const results = yield call(boiler, 'createSimulationComment', repo, simId, comment)
  const res = { message: `Create Simulation Comment: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempComments = cloneDeep(assemble.comments)
    tempComments.push(results.data)
    res.comments = tempComments
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* deleteSimulationComment({ repo, simId, commentId }) {
  const results = yield call(boiler, 'deleteSimulationComment', repo, simId, commentId)
  const res = { message: `Delete Simulation Comment: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempComments = cloneDeep(assemble.comments)
    for (let i = 0; i < tempComments.length; i++) {
      if (tempComments[i].id === commentId) tempComments.splice(i, 1)
    }
    res.comments = tempComments
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* getSimulations({ repo }) {
  const results = yield call(boiler, 'getSimulations', repo)
  const res = { ...results.data, message: `Get Simulations: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* loadSimulation({ repo, simId }) {
  const results = yield call(boiler, 'loadSimulation', repo, simId)
  const assemble = yield select(getState)
  const res = {
    simulation: '',
    comments: [],
    sequences: [],
    bg_color: '',
    simulationDownload: '',
    simulationPdf: '',
    image: '',
    name: '',
    simId: '',
    edit_round: 0,
    approved_by: null,
    approved_on: null,
    layout: '',
    app_name: '',
    app_host: '',
    web_app_name: '',
    web_host: '',
    app_icon: '',
    present_url: '',
    script_url: '',
    description: '',
    tags: [],
    auto_run: 'false',
    has_horizontal: false,
    marketingExp: '',
    mx_headline: '',
    mx_subhead: '',
    mx_button_text: '',
    mx_nav_prompt_text: '',
    initial_screen: true,
    options: assemble.options || {},
    importedSimId: '',
    training_video: '',
    ...results.data,
    message: `Load Simulation: ${results.message}`,
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* archiveSimulation({ repo, simId }) {
  const results = yield call(boiler, 'archiveSimulation', repo, simId)
  const res = { message: `Archive Simulation: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSimulations = cloneDeep(assemble.simulations)
    for (let i = 0; i < tempSimulations.length; i++) {
      if (tempSimulations[i].id === simId) tempSimulations.splice(i, 1)
    }
    res.simulations = tempSimulations
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* deleteSimulation({ repo, simId }) {
  const results = yield call(boiler, 'deleteSimulation', repo, simId)
  const res = { message: `Delete Simulation: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSimulations = cloneDeep(assemble.simulations)
    for (let i = 0; i < tempSimulations.length; i++) {
      if (tempSimulations[i].id === simId) tempSimulations.splice(i, 1)
    }
    res.simulations = tempSimulations
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* duplicateSimulation({ repo, simId, newSimName }) {
  const results = yield call(boiler, 'duplicateSimulation', repo, simId, newSimName)
  const res = { message: `Duplicate Simulation: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    res.simulations = [...assemble.simulations, results.data]
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* importSimulation({ repo, file }) {
  const results = yield call(boiler, 'importSimulation', repo, file)
  const res = { message: `Import Simulation: ${results.message}`, importedSimId: results.data?.id }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* generateSimulation({ repo, simId }) {
  const results = yield call(boiler, 'generateSimulation', repo, simId)
  const res = { message: `Generate Simulation: ${results.message}` }
  if (results.status === 'Success') res.simulationDownload = results.data.filepath
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* exportSimulation({ repo, simId }) {
  const results = yield call(boiler, 'exportSimulation', repo, simId)
  const res = { message: `Export Simulation: ${results.message}` }

  const bb = new Blob([results.data])
  bb.lastModifiedDate = new Date()
  bb.name = 'archive.zip'
  // bb.type = "zip";

  const zip = new JSZip()
  zip
    .loadAsync(bb)
    .then(function(zip) {
      zip
        .file('archived.json')
        .async('string')
        .then(function(content) {
          // eslint-disable-next-line no-console
          console.log(content)
          // content is the file as a string
        })
    })
    .catch(() => {})

  // const blob = new Blob([results.data], { type: 'application/zip' });
  const url = window.URL.createObjectURL(bb)
  if (results.status === 'Success') res.simulationDownload = url
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* generatePdf({ repo, simId }) {
  const results = yield call(boiler, 'generatePdf', repo, simId)
  const res = { message: `Generate PDF: ${results.message}` }
  const blob = new Blob([results.data], { type: 'application/pdf;base64' })
  if (results.status === 'Success') res.simulationPdf = window.URL.createObjectURL(blob)
  // window.URL.revokeObjectURL(blob);
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* createSequence({ repo, simId, data }) {
  const results = yield call(boiler, 'createSequence', repo, simId, data)
  const res = { message: `Create Sequence: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    if (typeof data.position === 'number') {
      tempSequences.splice(data.position, 0, results.data)
      res.sequences = tempSequences
    } else {
      res.sequences = [...assemble.sequences, results.data]
    }
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* updateSequence({ repo, simId, seqId, data }) {
  const results = yield call(boiler, 'updateSequence', repo, simId, seqId, data)
  const res = { message: `Update Sequence: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i <= tempSequences.length; i++) {
      if (tempSequences[i] && tempSequences[i].id === seqId) tempSequences.splice(i, 1, results.data)
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* duplicateSequence({ repo, simId, seqId }) {
  const results = yield call(boiler, 'duplicateSequence', repo, simId, seqId)
  const res = { message: `Duplicate Sequence: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    let index = -1
    for (let i = 0; i < tempSequences.length; i++) {
      if (tempSequences[i].id === seqId) index = i
    }
    if (index > -1) tempSequences.splice(index, 0, results.data)
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* transferSequence({ repo, simId, seqId, newSimId }) {
  const results = yield call(boiler, 'transferSequence', repo, simId, seqId, newSimId)
  const res = { ...results.data, message: `Transfer Sequence: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* deleteSequence({ repo, simId, seqId }) {
  const results = yield call(boiler, 'deleteSequence', repo, simId, seqId)
  const res = { message: `Delete Sequence: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i < tempSequences.length; i++) {
      if (tempSequences[i].id === seqId) tempSequences.splice(i, 1)
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* createSequenceBranch({ repo, simId, seqId, branch }) {
  const results = yield call(boiler, 'createSequenceBranch', repo, simId, seqId, branch)
  const res = { message: `Create Sequence: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    const index = findIndex(tempSequences, { id: seqId })
    const { branches = [] } = tempSequences[index]
    branches.push(branch)
    tempSequences[index].branches = branches
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* deleteSequenceBranch({ repo, simId, seqId, branch }) {
  const results = yield call(boiler, 'deleteSequenceBranch', repo, simId, seqId, branch)
  const res = { message: `Delete Sequence: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    const index = findIndex(tempSequences, { id: seqId })
    const { branches = [] } = tempSequences[index]
    branches.pop()
    tempSequences[index].branches = branches
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* createSequenceComment({ repo, simId, seqId, comment }) {
  const results = yield call(boiler, 'createSequenceComment', repo, simId, seqId, comment)
  const res = { message: `Create Sequence Comment: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i < tempSequences.length; i++) {
      if (tempSequences[i].id === seqId) tempSequences[i].comments.push(results.data)
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* deleteSequenceComment({ repo, simId, seqId, commentId }) {
  const results = yield call(boiler, 'deleteSequenceComment', repo, simId, seqId, commentId)
  const res = { message: `Delete Sequence Comment: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i < tempSequences.length; i++) {
      if (tempSequences[i].id === seqId) {
        const tempComments = tempSequences[i].comments
        for (let j = 0; j < tempComments.length; j++) {
          if (tempComments[j].id === commentId) tempSequences[i].comments.splice(j, 1)
        }
      }
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* updateContainer({ repo, simId, seqId, containerId, data }) {
  const newData = clone(data)
  if (newData.branch === 'a') delete newData.branch
  const results = yield call(boiler, 'updateContainer', repo, simId, seqId, containerId, newData)
  const res = { message: `Update Container: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i < tempSequences.length; i++) {
      for (let j = 0; j < DISPLAYS.length; j++) {
        const display = tempSequences[i][DISPLAYS[j]]
        if (display && display.id === containerId) {
          Object.assign(tempSequences[i][DISPLAYS[j]], results.data)
        }
      }
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* createContainerComment({ repo, simId, containerId, comment }) {
  const results = yield call(boiler, 'createContainerComment', repo, simId, containerId, comment)
  const res = { message: `Create Container Comment: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i < tempSequences.length; i++) {
      for (let j = 0; j < DISPLAYS.length; j++) {
        const display = tempSequences[i][DISPLAYS[j]]
        if (display && display.id === containerId) {
          tempSequences[i][DISPLAYS[j]].comments.push(results.data)
        }
      }
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* updateContainerComment({ repo, simId, containerId, commentId, data }) {
  const results = yield call(boiler, 'updateContainerComment', repo, simId, containerId, commentId, data)
  const res = { message: `Update Container Comment: ${results.message}` }
  if (results.status === 'Success') {
    const assemble = yield select(getState)
    const tempSequences = cloneDeep(assemble.sequences)
    for (let i = 0; i < tempSequences.length; i++) {
      for (let j = 0; j < DISPLAYS.length; j++) {
        const display = tempSequences[i][DISPLAYS[j]]
        if (display && display.id === containerId) {
          for (let k = 0; k < display.comments.length; k++) {
            if (display.comments[k].id === commentId) {
              if (data.status === 'deleted') {
                tempSequences[i][DISPLAYS[j]].comments.splice(k, 1)
              } else {
                tempSequences[i][DISPLAYS[j]].comments.splice(k, 1, { ...display.comments[k], ...data })
              }
            }
          }
        }
      }
    }
    res.sequences = tempSequences
  }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* updateSequenceOrder({ repo, simId, sequences }) {
  const results = yield call(boiler, 'updateSequenceOrder', repo, simId, {
    new_sequence_order: sequences.map(seq => `seq-${seq.id}`),
  })
  const res = { sequences, message: `Update Sequence Order: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* getRecentActivity({ simId }) {
  const results = yield call(boiler, 'getRecentActivity', simId)
  const res = { ...results.data, message: `Get Recent Activity: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}

export function* loadRecentActivity({ simId }) {
  const results = yield call(boiler, 'loadRecentActivity', simId)
  const res = { recentActivity: results.data.results || [], message: `Get Recent Activity: ${results.message}` }
  yield put(AssembleActions[`Assemble${results.status}`](res))
}
