// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import { ASSEMBLE_API_BASE_URL } from 'Config'
// our "constructor"
const create = (baseURL = ASSEMBLE_API_BASE_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      // 'Content-Type': 'application/json',
      // "Access-Control-Allow-Origin" : "*", // Required for CORS support to work
      // "Access-Control-Allow-Credentials" : true // Required for cookies, authorization headers with HTTPS
      // 'Cache-Control': 'no-cache'
    },
    // 3 minute timeout
    timeout: 1000 * 60 * 3,
  })

  const setHeaders = (Authorization, Customer) => api.setHeaders({ Authorization: `Bearer ${Authorization}`, Customer })

  const endpoints = {
    initiateRepo: () => api.get(`repo`), // Initialize a new repo clone
    commitRepo: repo => api.get(`repo/${repo}/commit`),
    refreshRepo: repo => api.get(`repo/${repo}/refresh`),
    resetRepo: repo => api.get(`repo/${repo}/reset`),
    createSimulation: (repo, newSimName, marketingExp) =>
      api.post(`repo/${repo}/simulations/${newSimName}`, { marketingExp }),
    updateSimulation: (repo, simId, data) => api.put(`repo/${repo}/simulations/${simId}`, data),
    createSimulationComment: (repo, simId, comment) =>
      api.post(`repo/${repo}/simulations/${simId}/comment`, {
        description: comment,
      }),
    deleteSimulationComment: (repo, simId, commentId) =>
      api.delete(`repo/${repo}/simulations/${simId}/comment/${commentId}`),
    getSimulations: repo => api.get(`repo/${repo}/simulations`),
    loadSimulation: (repo, simId) => api.get(`repo/${repo}/simulations/${simId}/load`),
    archiveSimulation: (repo, simId) => api.post(`repo/${repo}/simulations/${simId}/archive`),
    deleteSimulation: (repo, simId) => api.delete(`repo/${repo}/simulations/${simId}`),
    duplicateSimulation: (repo, simId, newSimName) =>
      api.post(`repo/${repo}/simulations/${simId}/duplicate`, {
        name: newSimName,
      }),
    importSimulation: (repo, file) => {
      const formData = new FormData()
      formData.append('file', file)
      return api.post(`repo/${repo}/import`, formData)
    },
    generateSimulation: (repo, simId) => api.get(`repo/${repo}/simulations/${simId}/generate`),
    exportSimulation: (repo, simId) => api.get(`repo/${repo}/simulations/${simId}/export`),
    generatePdf: (repo, simId) => api.get(`repo/${repo}/simulations/${simId}/pdf`),
    createSequence: (repo, simId, data) => api.post(`repo/${repo}/simulations/${simId}/sequence`, data),
    updateSequence: (repo, simId, seqId, data) => api.put(`repo/${repo}/simulations/${simId}/sequence/${seqId}`, data),
    duplicateSequence: (repo, simId, seqId) => api.get(`repo/${repo}/simulations/${simId}/sequence/${seqId}/duplicate`),
    transferSequence: (repo, simId, seqId, newSimId) =>
      api.post(`repo/${repo}/simulations/${simId}/sequence/${seqId}/transfer`, {
        simulation: newSimId,
      }),
    deleteSequence: (repo, simId, seqId) => api.delete(`repo/${repo}/simulations/${simId}/sequence/${seqId}`),
    createSequenceBranch: (repo, simId, seqId, branch) =>
      api.post(`repo/${repo}/simulations/${simId}/sequence/${seqId}/branch/${branch}`),
    deleteSequenceBranch: (repo, simId, seqId, branch) =>
      api.delete(`repo/${repo}/simulations/${simId}/sequence/${seqId}/branch/${branch}`),
    createSequenceComment: (repo, simId, seqId, comment) =>
      api.post(`repo/${repo}/simulations/${simId}/sequence/${seqId}/comment`, {
        description: comment,
      }),
    deleteSequenceComment: (repo, simId, seqId, commentId) =>
      api.delete(`repo/${repo}/simulations/${simId}/sequence/${seqId}/comment/${commentId}`),
    updateContainer: (repo, simId, seqId, containerId, data) =>
      api.put(`repo/${repo}/simulations/${simId}/sequence/${seqId}/container/${containerId}`, data),
    createContainerComment: (repo, simId, containerId, comment) =>
      api.post(`repo/${repo}/simulations/${simId}/container/${containerId}/comment`, comment),
    updateContainerComment: (repo, simId, containerId, commentId, data) =>
      api.put(`repo/${repo}/simulations/${simId}/container/${containerId}/comment/${commentId}`, data),
    // deleteContainerComment: (repo, simId, containerId, commentId) =>  return api.delete(`repo/${repo}/simulations/${simId}/container/${containerId}/comment/${commentId}`),
    // Updates the order of the sequences
    updateSequenceOrder: (repo, simId, sequences) =>
      api.put(`repo/${repo}/simulations/${simId}/order/sequence`, sequences),
    getRecentActivity: simId => api.get(`activity/recent/${simId}`),
    loadRecentActivity: simId => api.get(`activity/recent/load/${simId}`),
  }

  endpoints.callEndpoint = (endpoint, Authorization, Customer, ...data) => {
    setHeaders(Authorization, Customer)
    return endpoints[endpoint](...data)
  }

  return endpoints
}

export default { create }
