import React from 'react'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from 'styled-components'
import Navigation from './Navigation'
import UpdateSnackbar from './Components/UpdateSnackbar'
import ConfigLoader from './Components/ConfigLoader'
import { WEBSITE_CONFIG_ID } from './Config'
import breakpoints from './Theme/breakpoints'
import typography, { fontWeight } from './Theme/typography'
import palette from './Theme/palette'
import globals from './Theme/globals'
import zIndex from './Theme/zIndex'
import mixins from './Theme/mixins'
import spacing from './Theme/spacing'
import overrides from './Theme/overrides'

const theme = createTheme({
  typography,
  fontWeight,
  palette,
  globals,
  zIndex,
  mixins,
  spacing,
  breakpoints: { values: breakpoints },
  // shadows: ['none'],
  overrides,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
})

const App = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfigLoader id={WEBSITE_CONFIG_ID}>
        <Navigation />
      </ConfigLoader>
      {!window.location.href.includes('/public') && <UpdateSnackbar />}
    </ThemeProvider>
  </MuiThemeProvider>
)

export default App
