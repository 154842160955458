import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import ReactGA from 'react-ga'
import loadable from '@loadable/component'
import {
  createV6RouteForV5NoOptionalParam,
  createV6RouteForV5OptionalParam,
} from 'Utils/createRoutesFromPathWithOptionalParamAtEnd'
import { history } from 'Redux/CreateStore'
import ROUTES from 'enums/routes'
import GoogleTagManager from 'Components/GoogleTagManager'
import { GOOGLE_ANALYTICS, GOOGLE_TAG_MANAGER } from 'Config'

/** Import Each Page Component On-Demand * */
const AsyncLoginCallback = loadable(() => import('Containers/LoginCallback'))
const AsyncDashboard = loadable(() => import('Containers/Dashboard'))
const AsyncCollections = loadable(() => import('Containers/Collections'))
const AsyncExperienceControls = loadable(() => import('Containers/ExperienceControls'))
const AsyncLearnMode = loadable(() => import('Containers/LearnMode'))
const AsyncRemote = loadable(() => import('Containers/Remote'))
const AsyncLeftDisplayPage = loadable(() => import('Containers/LeftDisplayPage'))
const AsyncCenterDisplayPage = loadable(() => import('Containers/CenterDisplayPage'))
const AsyncRightDisplayPage = loadable(() => import('Containers/RightDisplayPage'))
const AsyncDisplayPage = loadable(() => import('Containers/DisplayPage'))
const AsyncSalesFirebase = loadable(() => import('Containers/SalesFirebase'))
const AsyncSalesPublic = loadable(() => import('Containers/SalesPublic'))
const AsyncError = loadable(() => import('Containers/Error'))
const AsyncTouch = loadable(() => import('Containers/Touch'))
const AsyncTouchSimulation = loadable(() => import('Containers/TouchSimulation'))
const AsyncSimulationDetailPage = loadable(() => import('Containers/SimulationDetailPage'))
const AsyncAssemble = loadable(() => import('Containers/Assemble'))
const AsyncEditSimulation = loadable(() => import('Containers/EditSimulation'))
const AsyncPreviewSimulation = loadable(() => import('Containers/PreviewSimulation'))
const AsyncMXHomePage = loadable(() => import('Containers/MXHomePage'))
const AsyncMXCollectionPage = loadable(() => import('Containers/MXCollectionPage'))
const AsyncMXLaunchPage = loadable(() => import('Containers/MXLaunchPage'))
const AsyncDetails = loadable(() => import('Containers/Details'))

const GAListener = ({ children }) => {
  const sendPageView = location => {
    if (GOOGLE_ANALYTICS) {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    }
  }
  useEffect(() => {
    if (GOOGLE_ANALYTICS) {
      ReactGA.initialize(GOOGLE_ANALYTICS)
      sendPageView(history.location)
      history.listen(sendPageView)
    }
  })
  return children
}

const Navigation = () => (
  <GAListener>
    <GoogleTagManager gtmId={GOOGLE_TAG_MANAGER} />
    <Routes>
      <Route path={ROUTES.DASHBOARD} element={<AsyncDashboard />} />
      <Route path={ROUTES.VERTICALS} element={<AsyncCollections />} />
      <Route path={ROUTES.EVENTS} element={<AsyncCollections />} />
      <Route path={ROUTES.CALLBACK} element={<AsyncLoginCallback />} />
      <Route path={ROUTES.MARKETING_EXPERIENCE} element={<AsyncMXHomePage />} />
      <Route path={ROUTES.MARKETING_EXPERIENCE_COLLECTION_ITEM} element={<AsyncMXCollectionPage />} />
      {createV6RouteForV5OptionalParam(ROUTES.MARKETING_EXPERIENCE_SIM_ITEM, AsyncMXLaunchPage)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_CENTER, AsyncExperienceControls)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_CENTER, AsyncExperienceControls)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_CENTER_LEARN_MODE, AsyncLearnMode)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_CENTER_LEARN_MODE, AsyncLearnMode)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_REMOTE, AsyncRemote)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_REMOTE, AsyncRemote)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_LEFT_DISPLAY, AsyncLeftDisplayPage)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_LEFT_DISPLAY, AsyncLeftDisplayPage)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_CENTER_DISPLAY, AsyncCenterDisplayPage)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_CENTER_DISPLAY, AsyncCenterDisplayPage)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_RIGHT_DISPLAY, AsyncRightDisplayPage)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_RIGHT_DISPLAY, AsyncRightDisplayPage)}
      {createV6RouteForV5OptionalParam(ROUTES.INNOVATION_CHOOSE_DISPLAY, AsyncDisplayPage)}
      {createV6RouteForV5NoOptionalParam(ROUTES.INNOVATION_CHOOSE_DISPLAY, AsyncDisplayPage)}
      {createV6RouteForV5OptionalParam(ROUTES.SIMULATION_DETAILS, AsyncSimulationDetailPage)}
      {createV6RouteForV5NoOptionalParam(ROUTES.SIMULATION_DETAILS, AsyncSimulationDetailPage)}
      {createV6RouteForV5OptionalParam(ROUTES.SALES_ENABLEMENT_PUBLIC_MX, AsyncSalesPublic)}
      {createV6RouteForV5NoOptionalParam(ROUTES.SALES_ENABLEMENT_PUBLIC_MX, AsyncSalesPublic)}
      {createV6RouteForV5OptionalParam(ROUTES.SALES_ENABLEMENT_PUBLIC, AsyncSalesPublic)}
      {createV6RouteForV5NoOptionalParam(ROUTES.SALES_ENABLEMENT_PUBLIC, AsyncSalesPublic)}
      {createV6RouteForV5OptionalParam(ROUTES.SALES_ENABLEMENT_FIREBASE, AsyncSalesFirebase)}
      {createV6RouteForV5NoOptionalParam(ROUTES.SALES_ENABLEMENT_FIREBASE, AsyncSalesFirebase)}
      <Route path={ROUTES.TOUCH} element={<AsyncTouch />} />
      {createV6RouteForV5OptionalParam(ROUTES.TOUCH_SIMULATION, AsyncTouchSimulation)}
      {createV6RouteForV5NoOptionalParam(ROUTES.TOUCH_SIMULATION, AsyncTouchSimulation)}
      <Route path={ROUTES.ASSEMBLE} element={<AsyncAssemble />} />
      <Route path={ROUTES.EDIT_SIMULATION} element={<AsyncEditSimulation />} />
      <Route path={ROUTES.PREVIEW_SIMULATION} element={<AsyncPreviewSimulation />} />
      <Route path={ROUTES.IC_DETAILS} element={<AsyncDetails />} />
      <Route path={ROUTES.ERROR} element={<AsyncError />} />
    </Routes>
  </GAListener>
)

export default Navigation
