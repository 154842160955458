import { call, put, select } from 'redux-saga/effects'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { push } from '@lagunovsky/redux-react-router'
import { get, mapKeys, mapValues, isObject } from 'lodash'
import TagManager from 'react-gtm-module'

import {
  COLLECTIONS_COLLECTION,
  SIMULATIONS_COLLECTION,
  USER_PROFILE,
  WEBSITE_CONFIG_ID,
  ENVIRONMENT,
  WEBSITE_CONFIG_COLLECTION,
  GOOGLE_TAG_MANAGER,
} from '../Config'

import AccountActions from '../Redux/AccountRedux'

const reduxSagaFirebase = new ReduxSagaFirebase(firebase)

const getFirestoreData = state => state.firestore.data
const getProfile = state => state.firebase.profile
const getAccount = state => state.account
const getConfig = state => state.firestore.data[WEBSITE_CONFIG_COLLECTION][WEBSITE_CONFIG_ID]

const fireGTM = (profile, firestoreData, account) => {
  // console.log('fireGTM', profile, firestoreData, account);
  if (GOOGLE_TAG_MANAGER && profile) {
    const simId = get(profile, 'simulation') || get(account, 'simulation.id')
    const colId = get(profile, 'collection')
    const triggers =
      get(firestoreData, `${SIMULATIONS_COLLECTION}.${get(profile, 'simulation')}.triggers`) ||
      get(account, 'simulation.triggers') ||
      []

    const tagManagerArgs = {
      dataLayer: {
        configId: get(profile, 'configId') || WEBSITE_CONFIG_ID,
        simId,
        simName: get(firestoreData, `${SIMULATIONS_COLLECTION}.${simId}.name`) || get(account, 'simulation.name'),
        collectionId: colId,
        collectionName: get(firestoreData, `${COLLECTIONS_COLLECTION}.${colId}.name`),
        triggerId: get(profile, 'trigger.id'),
        triggerIndex: get(profile, 'trigger.idx') + 1,
        totalTriggers: triggers.length,
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
}

const loginWithToken = token => firebase.auth().signInWithCustomToken(token)

const getIdToken = () => firebase.auth().currentUser.getIdToken()

const setUserCollection = (uid, collection) =>
  firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set({ collection }, { merge: true })

// const setUserSimulation = (uid, collection, simulation) =>
//   firebase
//     .firestore()
//     .collection("users")
//     .doc(uid)
//     .set({ collection, simulation }, { merge: true });

const setUserProfile = (uid, profile) => {
  // console.log('setUserProfile');
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set(profile)
}

/**
 * Trade the OIDC Gateway access_token for a Firebase ID Token
 * @param {*} api
 * @param {*} param1
 */
export function* getToken(api, { id_token, access_token }) {
  try {
    const results = yield call(api.getToken, access_token, id_token)

    // console.log("results error", results.data.error);
    // console.log("results token", results.data.token);

    if (results.data.token) {
      // We have a custom token.  login.
      const user = yield call(loginWithToken, results.data.token)
      // console.log(user);
      // console.log(user.user.uid);
      yield put(AccountActions.AccountSuccess({ uid: user.user.uid }))
    } else if (results.data.error) {
      yield put(AccountActions.AccountFailure(results.data.error.message || results.data.error))
    } else {
      yield put(AccountActions.AccountFailure('Unknown Error'))
    }
  } catch (error) {
    yield put(AccountActions.AccountFailure(error))
  }
}

export function* setCollection(api, { collection }) {
  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid
  if (uid) {
    yield call(setUserCollection, uid, collection)
    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

/**
 * Loads Collection and Simulation to Redux
 *
 * @param {*} api
 * @param {*} params
 */
export function* loadCollection(api, { collection }) {
  // console.log('loadCollection', collection);

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid
  if (uid) {
    // If we have been passed a collection.  Load that collection.
    const collectionSnapshot = yield call(
      reduxSagaFirebase.firestore.getDocument,
      `${COLLECTIONS_COLLECTION}/${collection}`,
    )
    const collectionData = collectionSnapshot.data()
    // console.log(collectionData);
    yield put(AccountActions.AccountSetCollection(collectionData))

    // Preload the Avaialable Simulations
    const simulationsSnapshot = yield call(
      reduxSagaFirebase.firestore.getCollection,
      firebase
        .firestore()
        .collection(SIMULATIONS_COLLECTION)
        .where('memberOf', 'array-contains', collection),
    )
    const simulations = {}
    // Loop Over Returned Data
    simulationsSnapshot.forEach(snapshot => {
      const snapshotData = snapshot.data()
      simulations[snapshotData.id] = snapshotData
      // console.log(snapshotData);
    })

    // Set Simulation in Redux Store
    yield put(AccountActions.AccountSetSimulations(simulations))

    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

export function* loadPublicSimulation(api, { token, simulation }) {
  let profile = {}
  try {
    const results = yield call(api.loadPublicSimulation, token, simulation, ENVIRONMENT)

    if (get(results, 'data.id')) {
      yield put(AccountActions.AccountSetPublicSimulation(results.data))

      // Set first trigger
      if (results.data.triggers && results.data.triggers[0]) {
        const trigger = results.data.triggers[0]
        trigger.idx = 0
        const profile = { trigger, path: null, sendEvent: null }
        yield put(AccountActions.AccountSetPublicProfile(profile))
      } else {
        yield put(AccountActions.AccountSetPublicProfile({ trigger: {} }))
      }

      const { publicprofile } = yield select(getAccount)
      profile = publicprofile

      yield put(AccountActions.AccountSetPublicProfile(publicprofile.set('path', null)))
      yield put(AccountActions.AccountSetPublicProfile(publicprofile.set('sendEvent', null)))

      yield put(AccountActions.AccountSetPublicProfile(publicprofile.set('path', null)))
    } else if (results.data.error) {
      yield put(AccountActions.AccountFailure(results.data.error.message || results.data.error))
    } else {
      yield put(AccountActions.AccountFailure('Unknown Error'))
    }
  } catch (error) {
    yield put(AccountActions.AccountFailure(error))
  }
  const firestoreData = yield select(getFirestoreData)
  const account = yield select(getAccount)
  yield call(fireGTM, profile, firestoreData, account)
}

/**
 * Set's User's Profile with collection, simulation
 * (optional) Triggers 1st Trigger
 *
 * @param {*} api
 * @param {*} params
 */
export function* sendNotification(api, { payload }) {
  // console.log('sendNotification', payload);
  const config = yield select(getConfig)

  if (config.sso) {
    try {
      const token = yield call(getIdToken)
      const results = yield call(api.sendNotification, payload, token)
      yield put(AccountActions.AccountSuccess(results))
    } catch (error) {
      console.error('error', error)
      yield put(AccountActions.AccountFailure(error))
    }
  }
}

export const replace = (obj, path) => {
  const overwrittenIDArr = []
  return mapKeys(obj, (value, key) => {
    if (key.includes(`_${path}`)) {
      const newKey = key.replace(`_${path}`, '')
      overwrittenIDArr.push(newKey)
      return newKey
    }
    if (overwrittenIDArr.includes(key)) return 'forget'
    return key
  })
}

export function* setTrigger(api, { data = {} }) {
  const { trigger, runTrigger, collectionId, simulationId, usepublic, path } = data

  let prof = {}
  let profile = yield select(getProfile)
  let triggerWithPath = {}
  let foundPath = path || profile.path
  foundPath = foundPath?.toLowerCase()

  if (trigger?.idx === 0 && !path) foundPath = null

  if (
    foundPath &&
    foundPath !== 'a' &&
    (typeof trigger?.branches === 'object'
      ? JSON.stringify(trigger.branches).includes(`"${foundPath}"`)
      : trigger?.branches?.includes(foundPath))
  ) {
    triggerWithPath = replace(trigger, foundPath)
    triggerWithPath = mapValues(triggerWithPath, value => {
      return isObject(value) && !Array.isArray(value) ? replace(value, foundPath) : value
    })
  } else triggerWithPath = trigger

  if (usepublic) {
    const { publicprofile } = yield select(getAccount)
    prof = { ...publicprofile, trigger: triggerWithPath, path: foundPath }
    const newPublicProfile = yield publicprofile?.set('trigger', triggerWithPath)?.set('path', foundPath)
    yield put(AccountActions.AccountSetPublicProfile(newPublicProfile))
  } else {
    const shouldRunTrigger = runTrigger || true

    const uid = firebase.auth().currentUser?.uid
    if (uid) {
      profile = { ...profile, trigger: triggerWithPath }
      if (foundPath) profile.path = foundPath
      // Delete extra params.
      delete profile.isEmpty
      delete profile.isLoaded

      // Always Set configId and Enviroment
      profile.configId = WEBSITE_CONFIG_ID
      profile.environment = ENVIRONMENT

      prof = profile

      if (collectionId !== undefined) profile.collection = collectionId
      if (simulationId !== undefined) profile.simulation = simulationId

      yield call(setUserProfile, uid, profile)

      if (trigger.phoneAction && trigger.phoneAction.data && shouldRunTrigger) {
        yield call(sendNotification, api, { payload: trigger.phoneAction })
      }

      yield put(AccountActions.AccountClearInteractionsClicked())
      yield put(AccountActions.AccountSuccess(true))
    } else {
      yield put(AccountActions.AccountFailure('user not logged in'))
    }
  }
  const firestoreData = yield select(getFirestoreData)
  const account = yield select(getAccount)
  yield call(fireGTM, prof, firestoreData, account)
}

export function* crossBrowserSendEventToIframes({ data }) {
  const uid = firebase.auth().currentUser?.uid

  if (uid) {
    let profile = yield select(getProfile)
    profile = {
      ...profile,
      sendEvent: {
        eventID: Math.random().toString(),
        data,
      },
    }
    if (!data) delete profile.sendEvent
    delete profile.isEmpty
    delete profile.isLoaded
    profile.configId = WEBSITE_CONFIG_ID
    profile.environment = ENVIRONMENT
    yield call(setUserProfile, uid, profile)
    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

export function* crossBrowserRelayIframeMessage({ data }) {
  const uid = firebase.auth().currentUser?.uid

  if (uid) {
    let profile = yield select(getProfile)
    profile = {
      ...profile,
      relayEvent: {
        eventID: Math.random().toString(),
        data,
      },
    }
    if (!data) delete profile.relayEvent
    delete profile.isEmpty
    delete profile.isLoaded
    profile.configId = WEBSITE_CONFIG_ID
    profile.environment = ENVIRONMENT
    yield call(setUserProfile, uid, profile)
    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

export function* setSimulation(api, { collection, simulation, present, runTrigger, nextUrl, newTab }) {
  const shouldRunTrigger = runTrigger || true

  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid
  if (uid) {
    let profile = yield select(getProfile)
    profile = { ...profile }

    profile.configId = WEBSITE_CONFIG_ID
    profile.environment = ENVIRONMENT

    // If we have been passed a collection.  Load that collection.
    if (collection) {
      profile.collection = collection
    } else {
      // Collection was not passed, update profile
      delete profile.collection
    }

    // If we were passed a simulation, see if we need to load it
    if (simulation) {
      profile.simulation = simulation
    } else {
      delete profile.simulation
    }

    // Delete extra params.
    delete profile.isEmpty
    delete profile.isLoaded

    if (simulation) {
      // Load Simulation Contents
      const simulationSnapshot = yield call(
        reduxSagaFirebase.firestore.getDocument,
        `${SIMULATIONS_COLLECTION}/${simulation}`,
      )
      const simulationData = simulationSnapshot.data() || {}

      const triggers =
        (present === 'present' && simulationData.present) ||
        (present === 'digital' && simulationData.digital) ||
        simulationData.triggers ||
        []

      const firstTrigger = triggers[0]
      const trigger = { ...firstTrigger, idx: 0 }
      profile.trigger = trigger

      if (shouldRunTrigger) {
        yield call(setTrigger, api, { data: { trigger, runTrigger: true } })
      }

      // Save Updated User Profile
      yield call(reduxSagaFirebase.firestore.setDocument, `${USER_PROFILE}/${uid}`, profile)
    } else {
      // Save Updated User Profile
      yield call(reduxSagaFirebase.firestore.setDocument, `${USER_PROFILE}/${uid}`, profile)
    }

    // Send Success back to client
    yield put(AccountActions.AccountSuccess(true))

    // Check to see if we need to change pages.
    if (nextUrl) {
      if (newTab) {
        window.open(nextUrl)
        yield
      } else {
        yield put(push(nextUrl))
      }
    }
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

export function* setHorizontal(api, { horiz, param }) {
  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid
  if (uid) {
    let profile = yield select(getProfile)
    profile = { ...profile }
    profile[param] = horiz
    // Delete extra params.
    delete profile.isEmpty
    delete profile.isLoaded

    yield call(setUserProfile, uid, profile)

    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

export function* setProfileOptions(api, { params }) {
  const uid = firebase.auth().currentUser && firebase.auth().currentUser.uid
  if (uid) {
    let profile = yield select(getProfile)
    profile = { ...profile }
    for (let i = 0; i < params.length; i++) {
      profile[params[i].key] = params[i].value
    }
    // delete profile.autoRun;
    // Delete extra params.
    delete profile.isEmpty
    delete profile.isLoaded

    yield call(setUserProfile, uid, profile)

    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}

export function* setLearnModeTourVisited() {
  const uid = firebase.auth().currentUser?.uid
  if (uid) {
    let profile = yield select(getProfile)
    profile = { ...profile }
    profile.learnModeTourVisited = true
    // Delete extra params.
    delete profile.isEmpty
    delete profile.isLoaded
    yield call(setUserProfile, uid, profile)

    yield put(AccountActions.AccountSuccess(true))
  } else {
    yield put(AccountActions.AccountFailure('user not logged in'))
  }
}
